<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-container>
      <v-row justify="center">
        <v-dialog v-model="loading" max-width="290">
          <v-card>
            <v-card-text class="pa-5 headline text-center">
              <v-progress-circular
                indeterminate
                color="primary"
                class="mr-3"
              ></v-progress-circular>
              処理中
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-card :disabled="loading" ref="form" elevation="2" class="pa-5 mt-5">
      <v-card-title>
        施工事例の新規作成
      </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="status" :value="alert" dismissible text>
          {{ statusMessage }}
        </v-alert>

        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              ref="title"
              v-model="title"
              :rules="rules.title"
              :error-messages="errorMessages"
              label="タイトル"
              :maxLength="titleMaxWord"
            >
              <div
                v-if="title"
                slot="append-outer"
                class="text-no-wrap"
                v-text="'残り ' + (titleMaxWord - title.length) + ' 文字'"
              ></div>
            </v-text-field>
          </v-col>
          <v-col col="12" md="4">
            <v-dialog
              ref="post_date_dialog"
              v-model="post_date_model"
              :return-value.sync="post_date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text class="mt-2">
                  投稿日:
                  {{ post_date ? post_date : currentDate() }}
                  <v-icon size="16" right>$edit</v-icon>
                </v-btn>
                <v-text-field
                  dense
                  hidden
                  class="no-border"
                  v-model="post_date"
                  ref="post_date"
                  :error-messages="errorMessages"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="post_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="post_date_model = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.post_date_dialog.save(post_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-file-input
              ref="image"
              v-model="image"
              :rules="rules.image"
              label="メイン画像"
              show-size
              counter
              prepend-icon="mdi-camera"
              @change="selectedFile"
              accept="image/jpeg, image/jpg, image/png"
            >
            </v-file-input>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              ref="image_alt"
              v-model="image_alt"
              :rules="rules.image_alt"
              :error-messages="errorMessages"
              label="Altテキスト"
              maxLength="28"
              counter
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              ref="area"
              v-model="area"
              label="対応地域"
              maxLength="191"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-textarea
          ref="description"
          v-model="description"
          :rules="rules.description"
          rows="1"
          auto-grow
          label="説明"
          id="description"
          :maxLength="descriptionMaxWord"
          :value="description"
        >
          <div
            v-if="description"
            slot="append-outer"
            class="text-no-wrap"
            v-text="
              '残り ' + (descriptionMaxWord - description.length) + ' 文字'
            "
          ></div>
        </v-textarea>

        <div
          class="v-label my-2"
          :class="
            formHasErrors && (form.content == null || form.content == '')
              ? 'red--text'
              : ''
          "
        >
          内容
        </div>
        <vue-editor
          ref="content"
          v-model="content"
          :rules="rules.content"
          id="content"
          :editorToolbar="customToolbar"
          :error-messages="errorMessages"
          :customModules="customModulesForEditor"
          :editorOptions="editorSettings"
          :value="content"
          :class="
            formHasErrors && (form.content == null || form.content == '')
              ? 'mb-0 red--text'
              : 'mb-4'
          "
        ></vue-editor>
        <div
          class="mt-2 mb-3 d-block"
          :class="formHasErrors ? 'red--text' : 'd-none'"
          v-if="formHasErrors && (form.content == null || form.content == '')"
        >
          内容が必要です。
        </div>

        <template>
          <v-autocomplete
            v-model="select_categories"
            :items="post_categories"
            item-text="title"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="カテゴリーを選択してください。"
            dense
            chips
            deletable-chips
            small-chips
            solo
          ></v-autocomplete>

          <v-autocomplete
            v-model="select_tags"
            :items="post_tags"
            item-text="title"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="タグを選択してください。"
            multiple
            dense
            chips
            deletable-chips
            small-chips
            solo
          ></v-autocomplete>
          <v-switch
            ref="post_status"
            v-model="post_status"
            label="公開"
            @click="toggle"
            value="ACTIVE"
          ></v-switch>
        </template>
      </v-card-text>

      <v-divider class="mt-12"></v-divider>
      <v-card-actions>
        <v-btn text :to="{ name: 'Post' }">
          キャンセル
        </v-btn>
        <v-btn text @click="savePostToDraft">
          下書きとして保存
        </v-btn>
        <v-btn text @click="savePostAndPreview">
          プレビュー
        </v-btn>
        <v-spacer></v-spacer>
        <v-slide-x-reverse-transition>
          <v-tooltip v-if="formHasErrors" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="my-0"
                v-bind="attrs"
                @click="resetForm"
                v-on="on"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>フォームを更新</span>
          </v-tooltip>
        </v-slide-x-reverse-transition>
        <v-btn
          color="primary"
          text
          @click="submit"
          :loading="loading"
          :disabled="loading"
        >
          保存する
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import api from '@/services/api-methods'
import { VueEditor } from 'vue2-editor'

import Quill from 'quill'
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      post_date_model: false,
      post_date: null,
      customToolbar: [
        [{ color: [] }, { size: [] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'code-block'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ]
      ],
      customModulesForEditor: [{ alias: 'imageResize', module: ImageResize }],
      editorSettings: {
        modules: {
          imageResize: {}
        }
      },
      breadcrumbs: [
        {
          text: '施工事例管理',
          disabled: false,
          to: { name: 'Post' },
          exact: true
        },
        {
          text: '施工事例の新規作成',
          disabled: false,
          to: { name: 'CreatePost' },
          exact: true
        }
      ],
      title: '',
      description: '',
      content: null,
      image: null,
      image_alt: '',
      area: '',
      post_status: ['ACTIVE'],
      errorMessages: '',
      formHasErrors: false,
      alert: false,
      status: null,
      statusMessage: null,
      select_categories: [],
      post_categories: [],
      select_tags: [],
      post_tags: [],
      titleMaxWord: 28,
      descriptionMaxWord: 80,
      isPreview: false,
      rules: {
        title: [
          v => !!v || 'タイトルが必要です。',
          v =>
            (v && v.length <= this.titleMaxWord) ||
            `タイトルは${this.titleMaxWord}文字未満である必要があります`
        ],
        description: [
          v => !!v || '説明が必要です',
          v =>
            (v && v.length <= this.descriptionMaxWord) ||
            `説明は${this.descriptionMaxWord}文字未満である必要があります`
        ],
        content: [v => !!v || '必要なコンテンツ'],
        image: [v => !!v || 'フィーチャー画像が必要'],
        image_alt: [v => !!v || 'Altフィールドは必須です'],
        post_date: [v => !!v || 'Post date が必要です。']
      },
      loading: false
    }
  },
  created() {
    this.post_date = this.currentDate()
  },
  computed: {
    form() {
      return {
        title: this.title,
        description: this.description,
        content: this.content,
        image: this.image,
        image_alt: this.image_alt,
        area: this.area,
        post_date: this.post_date
      }
    },
    formData() {
      var formData = new FormData()
      formData.append('title', this.title)
      formData.append('description', this.description)
      formData.append('content', this.content)
      formData.append('post_date', this.post_date)
      formData.append(
        'status',
        this.post_status[0] ? this.post_status[0] : 'INACTIVE'
      )
      formData.append('tag', this.select_tags)
      formData.append('category', this.select_categories)
      formData.append('image_alt', this.image_alt)
      formData.append('area', this.area)

      // for (var i = 0; i < this.$refs.images.value.length; i++) {
      //   let file = this.$refs.images.value[i]
      //   formData.append('images[' + i + ']', file)
      // }
      formData.append('images[]', this.image)
      return formData
    }
  },
  beforeMount() {
    this.getCategories()
    this.getTags()
  },
  methods: {
    currentDate() {
      const current = new Date()
      let year = current.getFullYear()
      let month = (current.getMonth() + 1).toString().padStart(2, '0')
      let day = current
        .getDate()
        .toString()
        .padStart(2, '0')
      const date = `${year}-${month}-${day}`
      return date
    },
    toggle(e) {
      if (
        !this.post_status[0] ||
        this.post_status[0] == '' ||
        this.post_status[0] == 'ACTIVE'
      ) {
        this.post_status = ['INACTIVE']
      } else {
        this.post_status = ['ACTIVE']
      }
    },
    getCategories() {
      api
        .get('/categories')
        .then(result => {
          let resultArray = []
          result.data.data.map(function(item, key) {
            if (item.name != '') {
              resultArray.push({ id: item.id, title: item.name })
            }
          })
          this.post_categories = resultArray
        })
        .catch(err => {
          //
        })
    },
    getTags() {
      api
        .get('/tags')
        .then(result => {
          let resultArray = []
          result.data.data.map(function(item, key) {
            if (item.name != '') {
              resultArray.push({ id: item.id, title: item.name })
            }
          })
          this.post_tags = resultArray
        })
        .catch(err => {
          //
        })
    },
    resetForm() {
      this.errorMessages = []
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (f != 'content') {
          this.$refs[f].reset()
        }
      })
      this.content = ''
      this.select_tags = ''
      this.select_categories = ''
    },
    selectedFile(e) {
      const imageField = e
      if (imageField && imageField.name !== '') {
        let imageSize = imageField.size / 1000
        // Check Image size less than 5MB
        if (imageSize > 5000) {
          this.rules.image.push('ファイルサイズは5MBまでです。')
        } else {
          this.rules.image = []
        }

        if (
          imageField.type != 'image/jpeg' &&
          imageField.type != 'image/jpg' &&
          imageField.type != 'image/png'
        ) {
          this.rules.image.push(
            '実績投稿で使用できる画像ファイル形式はjpgとpngです'
          )
        }

        const reader = new FileReader()
        reader.readAsDataURL(imageField)
        reader.onload = evt => {
          let img = new Image()
          img.onload = () => {
            if (img.width < '350') {
              this.rules.image = [
                '画像の横幅は350ピクセル以上のファイルをアップロードして下さい。- ※ 推奨の横幅は800ピクセル以上'
              ]
            }
          }
          img.src = evt.target.result
        }
      } else {
        this.rules.image.push('フィーチャー画像が必要')
      }
    },
    savePostToDraft() {
      this.post_status = ['DRAFT']
      this.submit()
    },
    savePostAndPreview() {
      this.post_status = ['INACTIVE']
      this.isPreview = true
      this.submit()
    },

    submit() {
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (f != 'content' && f != 'area') {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        }
      })
      if (!this.formHasErrors) {
        this.createPost()
      }
    },
    createPost() {
      this.loading = true
      api
        .add('posts', this.formData)
        .then(res => {
          this.status = res.data.status
          this.statusMessage = res.data.message
          if (this.isPreview) {
            this.isPreview = false
            this.$router.push({
              name: 'PreviewPost',
              params: { id: res.data.data.id }
            })
          }
          this.resetForm()
          this.loading = false
          let payload = {
            status: res.data.status,
            message: res.data.message
          }
          this.$store.commit('setAlert', payload)
          this.$router.push({ name: 'Post' })
          // this.alert = true
          // setTimeout(() => {
          //   this.alert = false
          // }, 5000)
        })
        .catch(e => {
          this.loading = false
        })
    }
  }
}
</script>
